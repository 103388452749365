import type { NoteResponse, MappedNote, NoteCategoryApiResponse, MappedNoteCategory } from '@/components/internal/account/notes/types'

const sanitizeJSON = (jsonString: string) => {
	// Removing comments
	jsonString = jsonString?.replace(/\/\*[\s\S]*?\*\/|([^\\:]|^)\/\/.*$/gm, '$1')

	// Adding double quotes to unquoted keys
	jsonString = jsonString?.replace(/(['"])?([a-zA-Z0-9_]+)(['"])?:/g, '"$2":')

	// Removing trailing commas from objects and arrays
	jsonString = jsonString?.replace(/,(?=\s*?[}\]])/g, '')

	// Escape unescaped forward slashes
	jsonString = jsonString?.replace(/([^\\])\/([^/])/g, '$1\\/$2')

	// Replace -> with →
	jsonString = jsonString?.replace(/->/g, '→')

	return jsonString || ''
}

const sanitizeString = (string: string) => {
	// Replace -> with →
	string = string?.replace(/->/g, '→')
	return string || ''
}

const sanitizeHTML = (htmlString: string) => {
	// Replace -> with →
	htmlString = htmlString?.replace(/->/g, '→')

	return htmlString || ''
}

const parseJSON = (jsonString: string) => {
	try {
		return JSON.parse(jsonString)
	} catch (e) {
		// TODO: Report this error?
		return 'ERROR PARSING JSON: ' + jsonString
	}
}

const mapNoteResponse = (response: NoteResponse): MappedNote => {
	const { noteAuthor, noteBody, sticky, noteJson, createdTime, noteCategory, lastModified } = response
	let items = ''
	if (noteJson?.items) {
		if (Array.isArray(noteJson?.items)) {
			items = JSON.stringify(noteJson?.items)
		} else {
			items = sanitizeJSON(noteJson?.items)
			// NOTE: This is a fix to remove the "last_modified" key from the items string
			// as BE has not properly escaped the quotes in the ISO date string.
			// So we can remove the "last_modified" key and everything after until and including the next comma,
			// from the items string.
			// items = items.replace(/"last_modified":.*?,/g, '')
			// NOTE: I'm choosing to let this be for now and just display the unformatted string.
		}
	}

	const hasNoteObjects = noteJson && Object?.keys(noteJson)?.length

	if (hasNoteObjects) {
		Object?.keys(noteJson)?.forEach((key) => {
			if (typeof noteJson[key] === 'string') {
				noteJson[key] = sanitizeString(noteJson[key])
			}
		})
	}

	const mappedNote: MappedNote = {
		id: response.noteId,
		author: noteAuthor || 'System',
		body: sanitizeHTML(noteBody),
		sticky,
		json: {
			...noteJson,
			items: items ? parseJSON(items) : {}
		},
		created: new Date(createdTime),
		lastModified: lastModified ? new Date(lastModified) : undefined,
		category: {
			id: noteCategory.noteCategoryId,
			name: noteCategory.noteCategory
		}
	}

	return mappedNote
}

const mapNoteCategories = (categories: NoteCategoryApiResponse[]): MappedNoteCategory[] => {
	return categories.map(({
		noteCategoryId,
		noteCategory
	}) => ({
		id: noteCategoryId,
		name: noteCategory
	}))
}

export { mapNoteResponse, mapNoteCategories }
